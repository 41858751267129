


































































import { RouterNames } from "@/router/routernames";
import { enumeracion } from "@/shared/dtos/enumeracion";
import { inventarioTareasDto } from "@/shared/dtos/tareas/inventarioTareasDto";
import { tareaAllDto } from "@/shared/dtos/tareas/tareaAllDto";
import { MessageService } from "@/shared/services/message-service";
import enumeracionModule from "@/store/modules/enumeracion-module";
import tareaModule from "@/store/modules/tarea-module";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class tareas_lista_movil extends Vue {
  @Prop({ default: -1 }) id_inventario!: number;
  public enumeracines_estados: enumeracion[] = [];
  public tareas_a_ver: boolean = false;

  created() {
    this.refres();
    enumeracionModule.getenumeraciones_fromtipo(6).then((x: enumeracion[]) => {
      this.enumeracines_estados = x;
    });
  }
  refres() {
    tareaModule.gettareaes_movil_id(this.id_inventario);
  }

  public get tareas() {
    /*return tareaModule.tareas_inventario.filter((x) =>
      x.tareas.filter((k) => k.estado.estate_closed === this.tareas_a_ver)
    );*/
    let datas: inventarioTareasDto[] = [];
    for (let i = 0; i < tareaModule.tareas_inventario.length; i++) {
      const element = tareaModule.tareas_inventario[i];
      let elementos: tareaAllDto[] = [];

      if (this.tareas_a_ver) {
        elementos = element.tareas.filter(
          (k) =>
            k.estado.estate_closed === this.tareas_a_ver ||
            k.estado.estate_supervised === true
        );
      } else {
        elementos = element.tareas.filter(
          (k) =>
            k.estado.estate_closed === this.tareas_a_ver &&
            k.estado.estate_supervised === false
        );
      }
      if (elementos.length > 0) {
        element.tareas = elementos;
        datas.push(element);
      }
    }

    if (this.id_inventario !== -1) {
      datas = datas.filter((x) => x.inventario.id);
    }

    return datas;
  }
  public get estados() {
    return this.enumeracines_estados;
  }
  public complete(tarea: tareaAllDto) {
    MessageService.confirm(
      "¿Seguro que quiere completar la tarea " + tarea.nombre + "?",
      "Completar",
      (x: boolean) => {
        if (x) {
          tarea.progreso = 100;
          let estado_supervisar = this.estados.find(
            (k) => k.estate_supervised == true
          );
          if (estado_supervisar !== undefined) {
            tarea.estado = estado_supervisar;
          } else {
            tarea.estado = this.estados.find((k) => k.estate_closed == true)!;
          }
          tarea.fecha_check = new Date();
          tarea.id_estado = tarea.estado.id;
          tareaModule.modificartarea(tarea).then((x) => this.refres());
        }
      }
    );
  }
  public open(tarea: tareaAllDto) {
    this.$router.push({
      name: RouterNames.todas_tareas_movil_formulario,
      params: { id: tarea.id },
    });
  }
}
